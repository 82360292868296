<template>
    <div>
        <b-row>
            <b-col cols="12">
                <label>Request Detail</label>
                <b-form-input v-model="selectedTrigger.description"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label>Contract Type<span class="text-red">*</span></label>
                <b-form-select v-if="!isLoading" v-model="selectedTrigger.contractTypeId" :disabled="selectedTrigger.company.description === null">
                    <b-form-select-option v-for="(item, index) in contractTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4">
                <label>Ticket status upon logged <span class="text-red">*</span></label>
                <b-form-select v-model="selectedTrigger.ticketSatusId">
                    <b-form-select-option v-for="(item, index) in statuses" :key="index" :value="item.pkStatusId">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4">
                <label>Ticket type <span class="text-red">*</span></label>
                <b-form-select v-model="selectedTrigger.callTypeId">
                    <b-form-select-option v-for="(item, index) in callTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row v-if="isLoading === false">
            <b-col cols="4">
                  <label>Technician</label>
                  <b-input-group>
                    <b-form-input disabled v-model="selectedTrigger.technitianName"></b-form-input>
                    <b-input-group-append>
                      <b-button size="sm" text="Button" variant="secondary" @click="openTechnicianModal"
                        >Search</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <b-form-select v-model="selectedJobCard.technicianId">
                                        <b-form-select-option v-for="(item, index) in technicians" :key="index" :value="item.userId">{{item.name}}</b-form-select-option>
                                    </b-form-select> -->
                </b-col>
            <b-col cols="4">
                <label>Priority <span class="text-red">*</span></label>
                <b-form-select v-model="selectedTrigger.priorityId">
                    <b-form-select-option v-for="(item, index) in priorities" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4" v-for="(listItem, index) in secondaryRequestTypes" :key="index">
                <label :class="index > 0 ? 'mt-2' : ''">Request {{index > 0 ? 'Sub Type ' + index : 'Type'}} <span class="text-red">*</span></label>
                <b-form-select v-model="listItem.value" @change="loadNextRequestType(listItem, index)">
                    <b-form-select-option v-for="(item, index) in listItem.list" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <!-- <b-col cols="4">
                <label>Technician Group</label>
                <b-input-group>
                    <b-form-input disabled v-model="selectedTrigger.technitianGroup"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col cols="4">
                <label>Technician</label>
                <b-form-select v-model="selectedTrigger.technitianID">
                    <b-form-select-option v-for="(item, index) in technicians" :key="index" :value="item.userId">{{item.name}}</b-form-select-option>
                </b-form-select>
            </b-col> -->
        </b-row>


    <b-modal id="search-technician-modal" size="xl" hide-footer no-header @close="closeTechnicianSearchModal">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="technicianSearchField.name"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Surname</label>
          <b-form-input v-model="technicianSearchField.surname"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearTechnicianSearchField">Clear</b-button>
          <b-button variant="primary" squared @click="filterTechs">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :items="technicianDataTable.dataSource"
          :fields="technicianDataTable.tableColumns"
          :busy="technicianDataTable.isLoading"
          @row-clicked="addTechnician"
          :per-page="technicianDataTable.resultsPerPage"
          id="technicianModalTable"
          :current-page="technicianDataTable.currentPage"
          sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button @click="addTechnician(row.item)" size="sm" class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination
          v-model="technicianDataTable.currentPage"
          :total-rows="technicianDataTableRows"
          :per-page="technicianDataTable.resultsPerPage"
          aria-controls="technicianModalTable"
        ></b-pagination>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="closeTechnicianSearchModal">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import { bus } from '../../main'
// import { required, email, minLength, maxLength, numeric } from "vuelidate/lib/validators";
export default {
    name: "details",
    data: () => ({
      compareContractType: 0,
        statuses: [],
      isLoading: false,
        callTypes: [],
        requestTypes: [],
        priorities: [],
        technicians: [],
        finalRequestTypeSelected: false,

    technicianSearchField: {
      name: null,
      surname: null
    },
    ogTechList: [],

    technicianDataTable: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'contactNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
          tdClass: '',
        },
      ],
    },
    }),
    created() {
        this.searchStatusForACL()
        .then((response) => {
          this.statuses = response.data
        })
        // if (this.selectedTrigger.company.description !== null) {
        //     this.getContractTypes()
        //     .then((response) => {
        //         this.contractTypes = response.data
        //     })
        // }

        this.getCallTypes()
        .then((response) => {
            this.callTypes = response.data
        })
        this.$store.commit('setRequestTypesRequest', 0)
        this.getRequestTypes()
        .then((response) => {
            if (response.data.length !== 0) {
                this.$store.commit('secondaryRequestTypeClear', [])
                this.secondaryRequestTypes.push({value: null, list: response.data})
                if (this.selectedTrigger.requestType !== undefined && this.selectedTrigger.requestType !== null) {
                    this.secondaryRequestTypes[0].value = this.selectedTrigger.requestType.id

                    if (this.selectedTrigger.requestType.subRequestTypes.length > 0) {
                        let subtypes = this.selectedTrigger.requestType.subRequestTypes[0]
                        let itemInList = response.data.find(element => element.id === this.selectedTrigger.requestType.id)
                        while (subtypes !== undefined) {
                            this.secondaryRequestTypes.push({value: subtypes.id, list: itemInList.subRequestTypes})
                            itemInList = itemInList.subRequestTypes.find(element => element.id === subtypes.id)
                            subtypes = subtypes.subRequestTypes[0]
                        }
                    }
                }
            }
        })
        this.getPriorityForTriggers()
        .then((response) => {
            this.priorities = response.data
        })

        // bus.$on('callContracts', () => {
        //   //console.log('called from non');
        //     this.getContractTypesByLocation()
        //     .then((response) => {
        //       //console.log('test 1: callContracts')
        //         this.contractTypes = response.data
        //     })
        // })
    },
    beforeDestroy(){
      // bus.$off()
    },
    methods: {
        ...mapActions(['searchTechnician','getCallTypes', 'getRequestTypes', 'getPriorityForTriggers', 'searchStatusForACL', 'searchTechnicianGroup', 'getContractTypesByLocation']),

    openTechnicianModal() {
      this.$bvModal.show('search-technician-modal');
      this.dosearchTechnician();
    },
    closeTechnicianSearchModal() {
      this.$bvModal.hide('search-technician-modal');
    },

    dosearchTechnician() {
      let request = [];
      this.technicianDataTable.isLoading = true
      if (this.technicianSearchField.name !== null) {
        request.push({ key: 'name', value: this.technicianSearchField.name });
      }
      if (this.technicianSearchField.surname !== null) {
        request.push({ key: 'surname', value: this.technicianSearchField.surname });
      }
      this.$store.commit('setTechnicianSearchRequest', request);
      this.searchTechnician().then((response) => {
        this.state = 'show';
        this.technicianDataTable.dataSource = response.data;
        this.ogTechList = response.data
        this.technicianDataTable.isLoading = false;
      });
    },
    filterTechs(){
      this.technicianDataTable.isLoading = true;

      let searchName = this.technicianSearchField.name !== null ? this.technicianSearchField.name.toLowerCase() : null;
      let searchSurname = this.technicianSearchField.surname !== null ? this.technicianSearchField.surname.toLowerCase() : null;
      let newArray = this.ogTechList.filter(function (el) {
          return (
          (el.name !== null ? el.name.toLowerCase().includes(searchName) : false) ||
          (el.surname !== null ? el.surname.toLowerCase().includes(searchSurname) : false)
          );
      });

      this.technicianDataTable.dataSource = newArray;
      this.technicianDataTable.isLoading = false;
    },
    addTechnician(rowItem) {
          this.isLoading = true
      //console.log('tech row item', rowItem);
      this.selectedTrigger.technitianName = rowItem.name + ' ' + rowItem.surname;
      this.selectedTrigger.technitianID = rowItem.userId;
      this.closeTechnicianSearchModal();
      this.isLoading = false
    },
    clearTechnicianSearchField() {
      this.technicianSearchField = {
        name: null,
        surname: null
      };

      this.technicianDataTable.isLoading = true;
      this.technicianDataTable.dataSource = this.ogTechList;
      this.technicianDataTable.isLoading = false;
    },

        loadNextRequestType(definedItem, index){
            let itemIndex = definedItem.list.findIndex(element => element.id === definedItem.value)
            if (index+1 < this.secondaryRequestTypes.length) {
                let listLength = this.secondaryRequestTypes.length
                for (let i = index+1; i <listLength; i++) {
                    this.secondaryRequestTypes.pop()
                }
                this.finalRequestTypeSelected = false
            }
            if (definedItem.list[itemIndex].subRequestTypes.length > 0) {
                this.secondaryRequestTypes.push({value: null, list: definedItem.list[itemIndex].subRequestTypes})
                this.finalRequestTypeSelected = false
            } else {
                // this.selectedTrigger.technitianGroup = definedItem.list[itemIndex].technitianGroup
                // this.selectedTrigger.technitianGroupId = definedItem.list[itemIndex].technitianGroupId
                this.finalRequestTypeSelected = true
            }
            // this.loadTechs()
        },
        // loadTechs(){
        //     if (this.selectedTrigger.company.description !== null && this.finalRequestTypeSelected === true) {
        //         //console.log('req', this.selectedTrigger)
        //         this.getTechBasedOnGroupAndRequestType()
        //         .then((res) => {
        //             //console.log('response', res)
        //         })
        //         .catch ((err) => {
        //             //console.log(err)
        //         })
        //     }
        // },
        goBackToSearch(){
            this.$router.back()
        },
    },
    computed:{
        ...mapState(['selectedTrigger', 'secondaryRequestTypes', 'contractTypes']),

    technicianDataTableRows() {
      return this.technicianDataTable.dataSource.length;
    },
    },
    validations:{},
}
</script>

<style scoped>

</style>