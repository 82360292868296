<template>
    <div>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button variant="primary" @click="openChecklistModal()">Add Checklist</b-button>
            </b-col>
        </b-row>


        <b-row>
            <b-col cols="12">
                <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                    :busy="tableData.isLoading" sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-h="end">
                            <b-button @click="removeFromList(row.item)" variant="red" size="sm" class="ml-2">Remove
                            </b-button>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableData.rows"
                :per-page="tableData.perPage"
                ></b-pagination>
        </b-row>

        <b-modal id="addItem-modal" hide-footer @close="hideModal()" size="xl">
            <b-row>
                <b-col>
                    <h4>Checklist Search</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                <b-alert :show="duplicateChecklistMessage"
                         dismissible
                         variant="warning">This checklist is already part of the list and cannot be added again</b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Description</label>
                    <b-form-input v-model="search.description"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Date Created From</label>
                    <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <label>Date Created To</label>
                    <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                    <b-button variant="primary" squared @click="callSearchChecklists()">Search</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="12">
                    <b-table striped hover :items="checklistTableData.dataSource" :fields="checklistTableData.tableColumns" 
                        :busy="checklistTableData.isLoading" @row-clicked="addChecklistRow" sort-icon-left>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>
                        <template #cell(checkListDate)="row">
                            <b-row align-v="center">
                                <span class="mr-auto">{{row.item.checkListDate | dateTimeFilter}}</span>
                            </b-row>
                        </template>
                        
                        <template #cell(actions)="row">
                            <b-row align-h="end">
                                <b-button @click="addChecklistRow(row.item)" class="btn-icon" size="sm">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>

                        
                    </b-table>
                </b-col>
            </b-row>
            <b-row align-h="center" >
                    <b-pagination
                    v-model="checklistTableData.currentPage"
                    :total-rows="checklistTableData.rows"
                    :per-page="checklistTableData.perPage"
                    ></b-pagination>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" @click="hideModal" class="mr-2 ml-2" squared>Close</b-button>
                </b-col>
            </b-row>
        </b-modal>
        

    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    data: () => ({
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Type',
                    key: 'checkListType',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        duplicateChecklistMessage: false,
        selectedChecklistAnswers: {},
        search:{
            description: null,
            dateCreatedFrom: null,
            dateCreatedTo: null
        },
        checklistTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Procedure Number',
                    key: 'procedureNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'checkListDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Created By',
                    key: 'createdBy',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        viewedChecklist: {categories: []},
        
    showIndex: -1,
        imageQuestion: {
            answerImages: []
        }
    }),
    created(){
        setTimeout(() => {
            if (this.selectedTrigger.checkLists === null) this.selectedTrigger.checkLists = []
            this.tableData.dataSource = this.selectedTrigger.checkLists
            this.tableData.isLoading = false
        }, 200)
    },
    methods:{
        ...mapActions(['searchChecklists']),
        
    changeShowIndex(newIndex){
      this.showIndex = newIndex
    },
        removeFromList(item){
            for (let i = 0; i < this.selectedTrigger.checkLists.length; i++) {
                const element = this.selectedTrigger.checkLists[i];
                if (element.checkListId === item.checkListId) {
                    this.selectedTrigger.checkLists.splice(i,1)
                    return
                }
            }
        },
        openChecklistModal(){
            this.$root.$emit('bv::show::modal', 'addItem-modal', '#btnShow')
            this.duplicateChecklistMessage = false
            this.callSearchChecklists()
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'addItem-modal', '#btnShow')
        },
        addChecklistRow(rowItem){
            let found = false
            //console.log(this.selectedTrigger)
            for (let i = 0; i < this.selectedTrigger.checkLists.length; i++) {
                const element = this.selectedTrigger.checkLists[i];
                if (rowItem.checkListId === element.checkListId) {
                    found = true
                }   
            }
            if (found === false) {
                this.selectedTrigger.checkLists.push(rowItem)
                this.hideModal()
            } else {
                this.duplicateChecklistMessage = true
            }
        },
        clearFilter(){
            this.search = {
                description: null,
                dateCreatedFrom: null,
                dateCreatedTo: null
            }
        },
        callSearchChecklists(){
            this.checklistTableData.isLoading = true
            
            let request = []
            if(this.search.description !== null){
                request.push({"key":"description","value": this.search.description})
            }
            if(this.search.dateCreatedFrom !== null){
                request.push({"key":"createdDateFrom","value": this.search.dateCreatedFrom})
            }
            if(this.search.dateCreatedTo !== null){
                request.push({"key":"createdDateTo","value": this.search.dateCreatedTo})
            }
            
            request.push({"key":"fkcheckListTypeid","value": 1})
            request.push({"key":"active","value": 'true'})

            this.$store.commit('setChecklistSearchRequest', request)
            this.searchChecklists()
            .then((res) => {
                this.checklistTableData.dataSource = res.data
                this.tableData.dataSource = this.selectedTrigger.checkLists
                this.checklistTableData.isLoading = false
            })
            .catch(() => {
                this.checklistTableData.isLoading = false
            })
        }
    },
    computed:{
        ...mapState(['selectedTrigger'])
    }
}
</script>