<template>
    <div>
        <h6 class="font-weight-bold px-3">
            Use this following to determine when the subscription is processed
        </h6>
        <b-row class="mt-3">
            <b-col cols="4">
                <label>Subscription Cycle <span class="text-red">*</span></label>
                <b-form-select v-model="selectedTrigger.scheduleTypeId" :options="options"></b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" v-if="selectedTrigger.scheduleTypeId === 4">
                <label>Nth Week</label>
                <b-form-input v-model="selectedTrigger.nthWeek" type="number"></b-form-input>
            </b-col>
            <b-col cols="4" v-if="selectedTrigger.scheduleTypeId === 1 || selectedTrigger.scheduleTypeId === 4">
                <label>Day of the week</label>
                <b-form-select v-model="selectedTrigger.scheduleDayOfWeek">
                    <b-form-select-option v-for="(item, index) in daysOfWeek" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4" v-if="selectedTrigger.scheduleTypeId === 3 || selectedTrigger.scheduleTypeId === 6 || selectedTrigger.scheduleTypeId === 7">
                <label>Month</label>
                <b-form-select v-model="selectedTrigger.scheduleMonth">
                    <b-form-select-option v-for="(item, index) in months" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4" v-if="selectedTrigger.scheduleTypeId === 2 || selectedTrigger.scheduleTypeId === 3 || selectedTrigger.scheduleTypeId === 6 || selectedTrigger.scheduleTypeId === 7">
                <label>Day of the month</label>
                <b-form-select v-model="selectedTrigger.scheduleDayOfMonth">
                    <b-form-select-option v-for="(item, index) in daysOfMonth" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="4" v-if="selectedTrigger.scheduleTypeId === 1 || selectedTrigger.scheduleTypeId === 2 || selectedTrigger.scheduleTypeId === 3 || selectedTrigger.scheduleTypeId === 4 || selectedTrigger.scheduleTypeId === 6 || selectedTrigger.scheduleTypeId === 7">
                <label>Time</label>
                <b-form-timepicker v-model="selectedTrigger.scheduleTime"></b-form-timepicker>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import { required, email, minLength, maxLength, numeric } from "vuelidate/lib/validators";
export default {
    name: "schedule",
    data: () => ({
        selected: null,
        options : [
            {value: 1, text: 'Specific day of the week'},
            {value: 2, text: 'Specific day of the month'},
            {value: 3, text: 'Annually day of the week'},
            {value: 4, text: 'Every nth week on a specific day at a specific time'},
            {value: 6, text: 'Quarterly at a specific time'},
            {value: 7, text: 'Bi-annually at a specific time'}
        ],
        daysOfWeek: [
            {id: 1, description: 'Monday'},
            {id: 2, description: 'Tuesday'},
            {id: 3, description: 'Wednesday'},
            {id: 4, description: 'Thursday'},
            {id: 5, description: 'Friday'},
            {id: 6, description: 'Saturday'},
            {id: 7, description: 'Sunday'}
        ],
        months: [
            {id: 1, description: 'January'},
            {id: 2, description: 'February'},
            {id: 3, description: 'March'},
            {id: 4, description: 'April'},
            {id: 5, description: 'May'},
            {id: 6, description: 'June'},
            {id: 7, description: 'July'},
            {id: 8, description: 'August'},
            {id: 9, description: 'September'},
            {id: 10, description: 'October'},
            {id: 11, description: 'November'},
            {id: 12, description: 'December'}
        ],
        daysOfMonth: []
    }),
    created() {
        for (let i = 1; i < 32; i++) {
            this.daysOfMonth.push({id: i, description: i.toString()})        
        }
    },
    methods: {
        goBackToSearch(){
            this.$router.back()
        },
    },
    computed:{
        ...mapState(['selectedTrigger'])
    },
    validations:{},
}
</script>

<style scoped>

</style>